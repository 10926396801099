import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SystemBaseColorList } from "@/service/settings";
import { SystemMyMenuList } from "@/service/system";
import store2 from "store2";
import { boolStr2Boolean } from "@/utils/util";

export const getBaseColorList = createAsyncThunk(
  "app/baseColorList",
  async () => {
    try {
      return await SystemBaseColorList({
        per_page: 999,
      });
    } catch (error) {
      console.log(error);
    }
  },
);

export const getMenuList = createAsyncThunk("app/menuList", async () => {
  try {
    return await SystemMyMenuList();
  } catch (error) {
    console.log(error);
  }
});

export const appSlice = createSlice({
  name: "app",
  initialState: {
    collapsed: false,
    baseColorList: [],
    menuList: store2("menus") || [],
  },
  reducers: {
    toggleCollapsed: (state, action) => {
      console.log(action)
      return (state = { ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBaseColorList.fulfilled, (state, action) => {
        if (action.payload) {
          state.baseColorList = action.payload.data.data || [];
        }
      })
      .addCase(getMenuList.fulfilled, (state, action) => {
        if (action.payload) {
          const menuList = action.payload.data.map((item) => {
            return {
              ...item,
              auth: boolStr2Boolean(item.auth),
              hidden: boolStr2Boolean(item.hidden),
              routes: item.routes.map((route) => {
                return {
                  ...route,
                  auth: boolStr2Boolean(route.auth),
                  hidden: boolStr2Boolean(route.hidden),
                };
              }),
            };
          });
          console.log(menuList);
          store2("menus", menuList);
          state.menuList = menuList;
        }
      });
  },
});

export const { toggleCollapsed } = appSlice.actions;

export default appSlice.reducer;
