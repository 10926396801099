import { configureStore } from "@reduxjs/toolkit";
import appReducer from "@/store/reducers/appSlice";
import authReducer from "@/store/reducers/authSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer
  },
});
