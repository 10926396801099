import request from "@/utils/request";
import { SERVERPATH } from "@/config";

// 角色列表
export function SystemRoleList(data) {
  return request({
    url: `${SERVERPATH}/Factory/System/roleList`,
    method: "post",
    data,
  });
}

// 角色详情
export function SystemRoleInfo(data) {
  return request({
    url: `${SERVERPATH}/Factory/System/roleInfo`,
    method: "post",
    data,
  });
}

// 角色新增
export function SystemRoleAdd(data) {
  return request({
    url: `${SERVERPATH}/Factory/System/roleAdd`,
    method: "post",
    data,
  });
}

// 角色修改
export function SystemRoleEdit(data) {
  return request({
    url: `${SERVERPATH}/Factory/System/roleEdit`,
    method: "post",
    data,
  });
}

// 角色删除
export function SystemRoleDel(data) {
  return request({
    url: `${SERVERPATH}/Factory/System/roleDel`,
    method: "post",
    data,
  });
}

// 后台菜单列表
export function SystemMenuList(data) {
  return request({
    url: `${SERVERPATH}/factory/System/menuList`,
    method: "post",
    data,
  });
}

// 角色授权
export function SystemAuthMenu(data) {
  return request({
    url: `${SERVERPATH}/factory/System/authMenu`,
    method: "post",
    data,
  });
}

// 用户列表
export function SystemUserList(data) {
  return request({
    url: `${SERVERPATH}/factory/System/userList`,
    method: "post",
    data,
  });
}

// 用户详情
export function SystemUserInfo(data) {
  return request({
    url: `${SERVERPATH}/factory/System/userInfo`,
    method: "post",
    data,
  });
}

// 用户新增
export function SystemUserAdd(data) {
  return request({
    url: `${SERVERPATH}/factory/System/userAdd`,
    method: "post",
    data,
  });
}

// 用户编辑
export function SystemUserEdit(data) {
  return request({
    url: `${SERVERPATH}/factory/System/userEdit`,
    method: "post",
    data,
  });
}

// 用户删除
export function SystemUserDel(data) {
  return request({
    url: `${SERVERPATH}/factory/System/userDel`,
    method: "post",
    data,
  });
}

// // 采购员列表
// export function SystemPurchaseList(data) {
//   return request({
//     url: `${SERVERPATH}/System/purchaseList`,
//     method: "post",
//     data,
//   });
// }

// // 跟单员列表
// export function SystemTrackerList(data) {
//   return request({
//     url: `${SERVERPATH}/System/trackerList`,
//     method: "post",
//     data,
//   });
// }

// 获取我有权限的菜单列表
export function SystemMyMenuList(data) {
  return request({
    url: `${SERVERPATH}/factory/System/myMenuList`,
    method: "post",
    data,
  });
}

// 系统通知列表
export function SystemnoticeList(data) {
  return request({
    url: `${SERVERPATH}/factory/Notice/noticeList`,
    method: "post",
    data,
  });
}

// 系统通知详情
export function SystemnoticeInfo(data) {
  return request({
    url: `${SERVERPATH}/factory/Notice/noticeInfo`,
    method: "post",
    data,
  });
}

// 系统通知删除
export function SystemnoticeDel(data) {
  return request({
    url: `${SERVERPATH}/factory/Notice/noticeDel`,
    method: "post",
    data,
  });
}
