import request from "@/utils/request";
import { SERVERPATH } from "@/config";

// 颜色列表
export function SystemBaseColorList(data) {
  return request({
    url: `${SERVERPATH}/factory/system/baseColorList`,
    method: "post",
    data,
  });
}
