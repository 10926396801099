import { USERINFO } from "@/config";
import store2 from "store2";

export const timeFix = () => {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
};

export const getAuthToken = () => {
  const userInfo = store2(USERINFO);
  if (userInfo) {
    return {
      token: userInfo.token,
      id: userInfo.id,
      isLogin: userInfo.token && userInfo.id,
    };
  }
  return {
    isLogin: false,
  };
};

export const handleRangeTime = (time) => {
  const [startTime, endTime] = time || [];
  return {
    startTime: startTime ? startTime.format("YYYY-MM-DD") : "",
    endTime: endTime ? endTime.format("YYYY-MM-DD") : "",
  };
};

export const getFirstImage = (imgUrlsString) => {
  if (typeof imgUrlsString === "string") {
    const imgUrlsArray = imgUrlsString.split(",").filter((url) => !!url);
    return imgUrlsArray.length > 0 ? imgUrlsArray[0] : null;
  } else if (Array.isArray(imgUrlsString)) {
    return imgUrlsString.length > 0 ? imgUrlsString[0] : null;
  } else {
    return null;
  }
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const getUploadFilePath = (imgObj) => {
  return imgObj?.response
    ? formatFilePath(imgObj.response.data[0])
    : formatFilePath(imgObj.url);
};

export const formatFilePath = (imgUrl) => {
  const regExp = /^http[s]{0,1}:\/\//;
  const pathname = "upload/";
  if (regExp.test(imgUrl)) {
    const [url] = imgUrl.split("?");
    if (regExp.test(url)) {
      const [, path] = url.split(pathname);
      return `${pathname}${path}`;
    }
  }
  return imgUrl;
};

export const boolStr2Boolean = (boolStr) => {
  if (boolStr === "true") {
    return true;
  } else if (boolStr === "false") {
    return false;
  } else {
    return false;
  }
};
