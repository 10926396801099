import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const PageLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // 重定向至指定路径
    if (location.pathname === props.path && props.redirect) {
      navigate(props.redirect, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <Outlet />;
};

PageLayout.propTypes = {
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default PageLayout;
