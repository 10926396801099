import React from "react";
import * as IconMaps from "@ant-design/icons";
import PropTypes from "prop-types";

const AntdIcon = (props) => {
  const iconMaped = IconMaps[props.icon];
  if (iconMaped) {
    return React.createElement(iconMaped, {
      style: {
        fontSize: props.size,
        color: props.color,
      },
    });
  }

  return null;
};

AntdIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default AntdIcon;
