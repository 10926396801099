import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "@/utils/util";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    permissioned: getAuthToken()["isLogin"],
    menus: [],
  },
  reducers: {
    togglePermissioned: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
  },
});

export const { togglePermissioned } = authSlice.actions;

export default authSlice.reducer;
