// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import PropTypes from "prop-types";
import React from "react";
import Menus from "@/components/Menus";
import styles from "./layout.module.less";
// import { toggleCollapsed } from "@/store/reducers/appSlice";
import { useSelector } from "react-redux";

const Sider = React.memo((props) => {
  // const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  console.log(app)

  return (
    <Layout.Sider
      theme="light"
      trigger={null}
      width={250}
      collapsedWidth={48}
      collapsible
      collapsed={app.collapsed}
      className={styles.sider}
    >
      <div className={styles.siderChildren}>
        <Menus routes={props.routes} />
        {/* <div
          className={styles.siderTriggers}
          style={{ paddingLeft: app.collapsed ? 14 : 28 }}
        >
          {React.createElement(
            app.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () =>
                dispatch(toggleCollapsed({ collapsed: !app.collapsed })),
            },
          )}
        </div> */}
      </div>
    </Layout.Sider>
  );
});

Sider.displayName = "Sider";

Sider.propTypes = {
  refresh: PropTypes.func,
  routes: PropTypes.array.isRequired,
};

export default Sider;
