import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Paragraph, Text } = Typography;
const ErrorFallBack = (props) => {
  console.log(props);
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
    setTimeout(() => {
      global.location.reload();
    }, 300);
  };

  return (
    <Result
      status="error"
      title="There are some errors with your operation"
      extra={
        <Button type="primary" onClick={() => onClick()}>
          Go Back
        </Button>
      }
    >
      <div className="desc">
        <Paragraph>
          <Text strong>The content you submitted has the following error:</Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined style={{ color: "red" }} /> {props.error.stack}
        </Paragraph>
      </div>
    </Result>
  );
};

ErrorFallBack.propTypes = {
  error: PropTypes.any,
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps)(ErrorFallBack);
