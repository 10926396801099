import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import styles from "./layout.module.less";
import { Avatar, Dropdown } from "antd";
import { useSelector, shallowEqual, connect } from "react-redux";
import { toggleCollapsed } from "@/store/reducers/appSlice";
import store2 from "store2";
import Logo from "@/assets/logo3.png";

const HeaderContent = (props) => {
  const navigate = useNavigate();
  const app = useSelector((state) => state.app, shallowEqual);
  const [user, setuser] = useState({});

  useEffect(() => {
    setuser(store2("__data__login_user") || {});
  }, []);

  const onClick = (e) => {
    console.log(e);
    if (e.key === "Logout") {
      store2.remove("__data__login_user");
      navigate("/login", {
        replace: true,
      });
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <img className={styles.headerLeftLogo} src={Logo} />
        <div className={styles.headerLeftTitle}>排排购后台管理</div>
        {app.collapsed ? (
          <MenuUnfoldOutlined
            style={{ fontSize: 20, color: "#FFFFFF" }}
            onClick={() =>
              props.toggleCollapsed({
                collapsed: false,
              })
            }
          />
        ) : (
          <MenuFoldOutlined
            style={{ fontSize: 20, color: "#FFFFFF" }}
            onClick={() =>
              props.toggleCollapsed({
                collapsed: true,
              })
            }
          />
        )}
      </div>
      <div>
        <Dropdown
          menu={{
            items: [
              {
                key: "Logout",
                icon: <LogoutOutlined />,
                label: "退出登录",
              },
            ],
            onClick: onClick,
          }}
          trigger={["click"]}
        >
          <div
            className={styles.headerRight}
            onClick={(e) => e.preventDefault()}
          >
            <Avatar icon={<UserOutlined />} />
            <div className={styles.headerRightText}>欢迎您，{user.account}</div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

HeaderContent.propTypes = {
  toggleCollapsed: PropTypes.func,
};

const mapStateToProps = (state) => state.app;
export default connect(mapStateToProps, { toggleCollapsed })(HeaderContent);
