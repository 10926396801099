import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const PermissionRoute = (props) => {
  const location = useLocation();
  const { permissioned } = useSelector((state) => state.auth, shallowEqual);

  const needAuth = props.route.auth;
  if (needAuth) {
    return permissioned ? (
      props.children
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }

  return props.children;
};

PermissionRoute.propTypes = {
  route: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default PermissionRoute;
