/*
 * @Description: 
 * @Author: lushunjie
 * @Date: 2024-02-26 17:12:24
 * @LastEditors: lushunjie
 * @LastEditTime: 2024-03-09 14:58:03
 * @FilePath: /ppg_admin/src/layout/index.jsx
 */
import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout } from "antd";
// import Footer from "./Footer";
import HeaderContent from "./HeaderContent";
import { useDispatch } from "react-redux";
import { getBaseColorList } from "@/store/reducers/appSlice";
import Sider from "./Sider";
import styles from "./layout.module.less";

const { Header, Content } = Layout;

const AppLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routes = useMemo(() => props.routes, [props.routes]);

  useEffect(() => {
    // 重定向至指定路径
    if (location.pathname === "/" && props.redirect) {
      navigate(props.redirect, { replace: true });
    }

    dispatch(getBaseColorList());
  }, [location.pathname]);

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <HeaderContent />
      </Header>

      <Sider routes={routes} />
      <div className={styles.content}>
        <Content>
          <Outlet />
        </Content>
        {/* <Footer /> */}
      </div>
    </Layout>
  );
};

AppLayout.propTypes = {
  routes: PropTypes.array,
  path: PropTypes.string,
  redirect: PropTypes.string,
  store: PropTypes.object,
};

export default AppLayout;
