import PropTypes from "prop-types";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";

import ErrorFallback from "@/components/ErrorFallback";

import PermissionRoute from "./PermissionRoute";

const CommonRoutes = (props) => {
  const location = useLocation();

  const { routes } = props;

  /**
   * @method renderRoute
   * @description 生成路由配置表中所有的路由，权限根据服务端返回菜单
   * @param {Array} routes
   * @return {ReactNode}
   * @example:
   */
  const renderRoute = (routes) => {
    return routes.map((route) => {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PermissionRoute route={route}>
              <Helmet>
                <title>{route.name} | BuyBuyGo</title>
              </Helmet>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <route.element {...route}></route.element>
              </ErrorBoundary>
            </PermissionRoute>
          }
        >
          {renderRoute(route.routes)}
        </Route>
      );
    });
  };

  return <Routes location={location}>{renderRoute(routes)}</Routes>;
};

CommonRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default CommonRoutes;
