export const HOST = "http://114.55.128.217";

export const DOMAIN = "http://114.55.128.217";

export const SERVERPATH = "";

export const USERINFO = "__data__login_user";

export default {
  HOST,
  SERVERPATH,
  USERINFO,
};
