import { message } from "antd";
import axios from "axios";
import qs from "qs";
import store2 from "store2";
import { USERINFO } from "@/config";

const pendingRequestMap = new Map();
const CodeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

// 创建 axios 实例
const instance = axios.create({
  timeout: 180000, // 请求超时时间
  headers: { "Content-Type": "application/json" },
});

// 自定义配置
const myOptions = {
  repeat_http_cancel: false, // 是否开启取消重复请求, 默认为 true
  reduct_data_format: false, // 是否开启简洁的数据结构响应, 默认为true
  code_message_show: true, // 是否开启code不为0时的信息提示, 默认为false
  error_message_show: true, // 是否开启接口错误信息展示,默认为true
};

// 异常拦截处理器
const errorHandler = (error) => {
  if (axios.isCancel(error)) {
    return console.error("重复请求：" + error.message);
  }
  const { response } = error;
  console.log(response);
  if (myOptions.error_message_show && response && response.status) {
    const errorText = CodeMessage[response.status] || response.statusText;
    const { status } = response;
    message.error(`请求错误 ${status}: ${errorText}`);
    return Promise.reject(error);
  }
  if (!response) {
    message.error("网络异常：您的网络发生异常，无法连接服务器");
  }
  // return Promise.reject(error);
};

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
/**
 * @method getPendingKey
 * @description 生成每个请求唯一的键
 * @param {*} config
 * @return {String}
 */
function getPendingKey(config) {
  let { url, method, params, data } = config;
  if (typeof data === "string") data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [method, url, qs.stringify(params), qs.stringify(data)].join("&");
}

/**
 * @method addPendingRequest
 * @description 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 * @return {*}
 */
function addPendingRequest(config) {
  const pendingKey = getPendingKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequestMap.has(pendingKey)) {
        pendingRequestMap.set(pendingKey, cancel);
      }
    });
}

/**
 * @method removePendingRequest
 * @description 删除重复的请求
 * @param {*} config
 * @return {*}
 */
function removePendingRequest(config) {
  const pendingKey = getPendingKey(config);
  if (pendingRequestMap.has(pendingKey)) {
    const cancelToken = pendingRequestMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingRequestMap.delete(pendingKey);
  }
}

// 请求拦截
instance.interceptors.request.use((config) => {
  removePendingRequest(config);
  myOptions.repeat_http_cancel && addPendingRequest(config);

  // store2(USERINFO) || {};
  config.headers.factoryId = store2(USERINFO)?.id;
  config.headers.factoryToken = store2(USERINFO)?.token;
  return config;
}, errorHandler);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    console.log(response);

    response.config && removePendingRequest(response.config);

    if (response.data && response.data.code === -1) {
      message.error("登录信息已过期，请重新登录");
      setTimeout(() => {
        const { origin, pathname } = window.location;
        window.location.href = `${origin}${pathname}#/login`;
      }, 800);
      return;
    }

    if (
      // code等于0
      myOptions.code_message_show &&
      response.data &&
      response.data.code === 0
    ) {
      message.error(response.data.msg);
      return Promise.reject(response.data);
    }

    console.log("response => ", response.config.url, response.data);

    return myOptions.reduct_data_format ? response.data.res : response.data;
  },
  (error) => {
    error.config && removePendingRequest(error.config);
    errorHandler(error);
  },
);

export default instance;
