import { Menu } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { getMenuList } from "@/store/reducers/appSlice";
import { useDispatch } from "react-redux";
import AntdIcon from "@/components/AntdIcon";

const AMenus = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app, shallowEqual);
  const auth = useSelector((state) => state.auth, shallowEqual);
  const [items, setItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    if (auth.permissioned) {
      dispatch(getMenuList());
    }
  }, [auth.permissioned]);

  useEffect(() => {
    !app.collapsed && findOpenKeysWrap();
  }, [app.collapsed]);

  useEffect(() => {
    if (app.menuList.length > 0) {
      setItems(getMenuItems(app.menuList));
    }
  }, [location.pathname, app.menuList]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [items, location.pathname]);

  const getMenuItems = (routes) => {
    return routes.map((route) => {
      if (route.routes && route.routes.length > 0) {
        return !route.hidden
          ? {
              children: getMenuItems(route.routes),
              disabled: route.disabled,
              icon: <AntdIcon icon={route.icon} />,
              key: route.path,
              label: route.name,
            }
          : null;
      }
      return !route.hidden
        ? {
            disabled: route.disabled,
            icon: <AntdIcon icon={route.icon} />,
            key: route.path,
            label: route.name,
            title: route.name,
          }
        : null;
    });
  };

  /**
   * @method findOpenKeys
   * @description 查找第一个展开的菜单
   * @param {Object} route 指定的顶级路由
   * @return {Array} 查询到的顶极路由下，所有匹配的第一层级路由的path
   */
  const findOpenKeysWrap = () => {
    const [firstRoute] = props.routes.filter((route) => location.pathname.includes(route.path));
    const openKeysCache = [];
    const findOpenKeys = (route) => {
      if (!route) {
        return;
      }
      if (route.routes && route.routes.length > 0) {
        openKeysCache.push(route.path);
      }
      findOpenKeys(route.routes[0]);
    };

    findOpenKeys(firstRoute);
    setOpenKeys(openKeysCache);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  console.log(items);

  return (
    <Menu
      mode="inline"
      theme="light"
      triggerSubMenuAction="click"
      style={{ height: "100%", background: "transparent", border: 0 }}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={items}
      onClick={({ key }) => {
        console.log(key);
        navigate(key);
      }}
    />
  );
};


AMenus.propTypes = {
  routes: PropTypes.array,
};

export default AMenus;
