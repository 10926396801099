import "./App.css";
import React from "react";
import { ConfigProvider } from "antd";
import { HashRouter } from "react-router-dom";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { Provider } from "react-redux";
import store from "@/store";
import ReactRouter from "@/config/react-router";
import CommonRoutes from "@/router";
import zhCN from "antd/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

dayjs.locale("zh-cn");

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <HashRouter>
          <StyleProvider transformers={[legacyLogicalPropertiesTransformer]}>
            <CommonRoutes routes={ReactRouter} />
          </StyleProvider>
        </HashRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
