import React from "react";
import loadable from "@loadable/component";
import AppLayout from "@/layout";
import PageLayout from "@/layout/PageLayout";
import { HomeOutlined, GiftOutlined, DesktopOutlined, MoneyCollectOutlined } from "@ant-design/icons";

const routes = [
  {
    path: "/",
    element: AppLayout,
    redirect: "/home",
    name: "首页",
    icon: null,
    auth: false,
    hidden: false,
    routes: [
      {
        path: "/home",
        element: loadable(() => import("@/views/Home")),
        redirect: "",
        name: "首页",
        icon: <HomeOutlined />,
        auth: true,
        hidden: false,
        routes: [],
      },
      {
        path: "/goods",
        element: PageLayout,
        redirect: "/goods/list",
        name: "商品管理",
        icon: <GiftOutlined />,
        auth: true,
        hidden: false,
        routes: [
          {
            path: "/goods/list",
            element: loadable(() => import("@/views/Goods/List")),
            redirect: "",
            name: "商品列表",
            icon: null,
            auth: true,
            hidden: false,
            routes: [],
          },
          {
            path: "/goods/content/:id",
            element: PageLayout,
            redirect: "",
            name: "商品详情",
            icon: null,
            auth: true,
            hidden: true,
            routes: [],
          },
        ],
      },
      {
        path: "/settlement",
        element: PageLayout,
        redirect: "/settlement/list",
        name: "结算管理",
        icon: <MoneyCollectOutlined />,
        auth: true,
        hidden: false,
        routes: [
          {
            path: "/settlement/list",
            element: loadable(() => import("@/views/Settlement/List")),
            redirect: "/settlement/list",
            name: "结算列表",
            icon: null,
            auth: true,
            hidden: false,
            routes: [],
          },
        ],
      },
      {
        path: "/system",
        element: PageLayout,
        redirect: "/system/role",
        name: "系统设置",
        icon: <DesktopOutlined />,
        auth: true,
        hidden: false,
        routes: [
          {
            path: "/system/role",
            element: loadable(() => import("@/views/System/Role")),
            redirect: "",
            name: "角色设置",
            icon: null,
            auth: true,
            hidden: false,
            routes: [],
          },
          {
            path: "/system/user",
            element: loadable(() => import("@/views/System/User")),
            redirect: "",
            name: "用户设置",
            icon: null,
            auth: true,
            hidden: false,
            routes: [],
          },
          {
            path: "/system/notification",
            element: loadable(() => import("@/views/System/Notification")),
            redirect: "",
            name: "系统通知",
            icon: null,
            auth: true,
            hidden: false,
            routes: [],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: loadable(() => import("@/views/Login/Login")),
    name: "登录",
    icon: null,
    auth: false,
    hidden: false,
    routes: [],
  },
  {
    path: "/error",
    element: loadable(() => import("@/components/500")),
    name: "500 服务器发生错误",
    hidden: false,
    routes: [],
  },
  {
    path: "/forbidden",
    element: loadable(() => import("@/components/403")),
    name: "403 禁止访问",
    hidden: false,
    routes: [],
  },
  {
    path: "*",
    element: loadable(() => import("@/components/404")),
    name: "404 页面未找到",
    hidden: false,
    routes: [],
  },
];

export default routes;
